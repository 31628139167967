<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
          sm="6"
        >
          <div>
            <div class="display-flex align-items-center">
              <p class="txt-title mb-0 mr-1 mon-bold">{{ texts.popularCatalog.titleSection }}</p>
              <img
                width="25px"
                height="25px"
                src="@/assets/images/estrellas.png"
                alt=""
              >
            </div>
          </div>
        </v-col>
        <v-col
          class="pb-0"
          cols="12"
          sm="6"
        >
          <div class="align-content">
            <v-btn
              to="/catalogs"
              class="button-show-more pa-0 mon-medium"
              text
            >
              {{ texts.popularCatalog.viewCatalogs }}
              <v-icon size="16px">
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <!-- <v-btn
              class="carousel-button-prev ml-5 hidden-from-desktop"
              @click="$refs.buttonPrev.click()"
            >
              <v-icon
                size="18px"
                color="#73777E"
              >
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-btn
              class="carousel-button-next ml-2 hidden-from-desktop"
              @click="$refs.buttonNext.click()"
            >
              <v-icon
                size="18px"
                color="#73777E"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loaderSkeleton">
          <home-popular-catalog-skeleton-layout />
        </v-col>
        <v-col
          v-else
          cols="12"
          sm="12"
        >
          <div v-if="arrPopularCatalogs.length > 0">
            <div class="scrolled-lateral">
              <div
                v-for="(item, index) in arrPopularCatalogs"
                :key="index"
                style="width: 120px; margin-right: 15px;"
              >
                <div
                  class="content-image-responsive"
                  :style="{ backgroundColor: `${item.sColorCode}` }"
                  @click="redirectCatalog(item.sCategoryId)"
                >
                  <img
                    v-if="Object.keys(item.oImages).length > 0"
                    :src="item.oImages.sm"
                    alt=""
                  >
                </div>
                <p
                  class="txt-name mon-medium"
                  @click="redirectCatalog(item.sCategoryId)"
                >
                  {{ item.sName }}
                </p>
              </div>
            </div>
            <!-- <carousel-component
              :dots="false"
              :nav="false"
              :items="1"
              style="position: relative"
              :responsive="responsiveCarousel"
              v-else
            >

              <div
                v-for="(item, index) in arrPopularCatalogs"
                :key="index"
              >
                <div
                  class="content-image"
                  :style="{ backgroundColor: `${item.sColorCode}` }"
                  @click="redirectCatalog(item.sCategoryId)"
                >
                  <img
                    v-if="Object.keys(item.oImages).length > 0"
                    :src="item.oImages.sm"
                    alt=""
                  >
                </div>
                <p
                  class="txt-name mon-medium"
                  @click="redirectCatalog(item.sCategoryId)"
                >
                  {{ item.sName }}
                </p>
              </div>
              <template slot="prev">
                <span ref="buttonPrev"></span>
              </template>
              <template slot="next">
                <span ref="buttonNext"></span>
              </template>
            </carousel-component> -->
          </div>
          <div v-else>
            <p class="text-empty-home mon-regular">{{ texts.popularCatalog.emptyText }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomePopularCatalogLayout",
  data() {
    return {
      //VARIABLES
      responsiveCarousel: {
        0: { items: 2, nav: false },
        425: { items: 4, nav: false },
        600: { items: 6, nav: false },
        960: { items: 9, nav: false },
        1264: { items: 10, nav: false },
        1636: { items: 10, nav: false },
        1904: { items: 10, nav: false },
      },
      loaderSkeleton: true,
      arrPopularCatalogs: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getPopularCatalogs();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    getPopularCatalogs: function () {
      this.loaderSkeleton = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories/rankings`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.loaderSkeleton = false;

          this.arrPopularCatalogs = response.data.results;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    redirectCatalog: function (id) {
      this.$router.push({
        name: "Subcatalog",
        params: { id: id },
      });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getPopularCatalogs();
      }
    },
  },
};
</script>

<style scoped>
.carousel-button-next,
.carousel-button-prev {
  min-width: 30px !important;
  height: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  padding: 0px !important;
}

.txt-name {
  text-align: center;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 14px;
  margin-top: 5px;
}

.txt-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.content-image:hover {
  cursor: pointer;
  opacity: 0.5;
}

.content-image {
  width: 95%;
  height: 110px;
  opacity: 1;
  border-radius: 19px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 19px;
  }
}

.content-image-responsive {
  width: 120px;
  height: 110px;
  opacity: 1;
  border-radius: 19px;
}

.content-image-responsive img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-responsive img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 19px;
  }
}

.scrolled-lateral {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.button-show-more::before {
  background-color: transparent !important;
}

.align-content {
  text-align: right;
}

.button-show-more {
  color: #0071ff;
  text-transform: initial;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: underline;
}

.txt-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.hidden-from-desktop {
  display: none;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
  .hidden-from-desktop {
    display: inline-flex;
  }
}

@media (max-width: 600px) {
  .align-content {
    text-align: left;
  }
}
</style>